.promosToggle {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}

.settingsContainer {
    display: flex;
    width: 80vw;
    margin-top:25px;
    margin-bottom:25px;
}

.toggleLabel {
    margin-right:10px;
}