.submitButton {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: auto;
    top:-75px;
    position: relative;
  }
  
.submitButton:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.submitButton:focus {
    outline: none;
}

textarea {
    width: 80vw;
    height: 400px;
    position: relative;
    background-color: #172d43;
    color:#FEF2C3;
    border: 0px;
    resize: none;
  }
  
textarea:focus {
    outline: none;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.errorBanner {
    display: flex;
    justify-content: space-between;
    background-color: red;
    border-radius: 4px;
    margin-top: 5px;
    width: 80vw;
    position: relative;
    background-color: #f14668;
    color:white;
}

.translucentButton {
    background: rgba(253, 185, 185, 0);
    color:#2b7e72;
    align-self: left;
    margin-left: 0px;
}

.translucentButton:hover {
    background: rgba(0, 0, 0, 0.1);
}

.translucentButton:focus {
    outline: none;
}

.errorMessage {
    align-self: center;
}