.appHeader {
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color:white;
    background-color: #2B7E72;
  }
  .inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  @media print {
    .header {
      display: none;
    }
    textarea {
      display: none;
    }
  }