.cardGroup {
    display: grid;
    grid-template-rows: repeat(3, 92mm);
    grid-template-columns: repeat(3, 66mm);
    grid-gap: 2px 2px;
}

.cardContainer {
    display: grid;
    top: -75px;
    position: relative;
}

.cardContainer img {
    width: 100%;
}

.cardPreview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media print {

    .inputContainer {
        display: none;
    }
    .cardGroup {
        page-break-after: always;
        grid-gap: 0px 4px;
    }

    .cardContainer {
        position: initial;
        top: 0px;
    }
}

@media not all and (min-resolution:.001dpcm) { @media {

    .cardGroup {
        display: grid;
        grid-template-rows: repeat(3, 81mm);
        grid-template-columns: repeat(3, 58mm);
        grid-gap: 2px 2px;
    }
    
}}